<template>
  <div :id="`TEMPLATE_FORM_${id}`" style="height: 100%, width: 100%">
    <b-modal
      v-model="state.modalShow"
      size="lg"
      :title="labelTitle"
      footer-class="footerClass"
      no-close-on-backdrop
      content-class="shadow"
      @hidden="$emit('update:show', false)"
    >
      <b-alert variant="danger" dismissible :show="showError" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }}
        <ul :show="showErrorDetail" class="mb-0">
          <template v-for="(item, index) in alertMsgDetails">
            <li :key="index">{{ item }}</li>
          </template>
        </ul>
      </b-alert>

      <b-form-group :label="$t('template.field.name')" label-for="name">
        <b-input-group>
          <b-form-input
            id="name"
            type="text"
            :data-vv-as="$t('template.field.name')"
            data-vv-name="template.name"
            data-vv-delay="500"
            v-model="template.name"
          ></b-form-input>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']" />
          &nbsp;&nbsp;{{ errors.first('template.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('template.field.description')" label-for="description">
        <b-form-textarea
          id="description"
          :placeholder="$t('template.placeholder.description')"
          :data-vv-as="$t('template.field.description')"
          data-vv-name="template.description"
          data-vv-delay="500"
          v-model="template.description"
          :max-rows="6"
          :rows="3"
        />
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDescriptionError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']" />
          &nbsp;&nbsp;{{ errors.first('template.description') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="success" disabled v-if="state.isSubmitting">
          <b-spinner small type="grow" />{{ $t('button.saving') }}
        </b-button>
        <b-button :disabled="disableOk" size="sm" variant="success" v-else @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { taskService, templateProjectService, projectService
} from '@/services';
import currencies from '@/views/management/script/currencies';

import { TaskTemplateDataUtil } from '@/components/Task/script/task.template.util';

export default {
  name: 'TaskSaveTemplateModal',
  props: {
    id: {
      type: String,
      default: `TEMPLATE_NEW_${strRandom(5)}`
    },
    title: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    taskIds: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: String
    }
    // ,
    // isChildOnly: {
    //   type: Boolean,
    //   default: false
    // }
  },
  data() {
    return {
      alertMsg: null,
      alertMsgDetails: [],
      state: {
        editable: false,
        isSubmitting: false,
        modalShow: false
      },
      template: {
        uuId: null,
        name: null,
        description: null
      }
    };
  },
  created() {
    this.fieldValidateUtil = fieldValidateUtil;
  },
  beforeDestroy() {
    this.fieldValidateUtil = null;
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    showErrorDetail() {
      return this.alertMsgDetails != null && this.alertMsgDetails.length > 0;
    },
    showNameError() {
      return fieldValidateUtil.hasError(this.errors, 'template.name');
    },
    showDescriptionError() {
      return fieldValidateUtil.hasError(this.errors, 'template.description');
    },
    exists() {
      return this.id && !this.id.startsWith('TEMPLATE_NEW_');
    },
    labelTitle() {
      return this.title ? this.title : this.$t('template.title_new');
    },
    disableOk() {
      return this.state.isSubmitting;
    }
  },
  watch: {
    show(newValue) {
      if (newValue != this.state.modalShow) {
        this.state.modalShow = newValue;
        this.alertMsg = null;
        this.alertMsgDetails.splice(0, this.alertMsgDetails.length);
        this.resetTemplateProperties();
      }
    }
  },
  methods: {
    async getDefaultValues({ projectId=null }={}) {
      const requests = [
        this.$store.dispatch('data/enumList')
      ]
      if (projectId != null) {
        requests.push(projectService.get([{ uuId: projectId}]))
      }
      return Promise.allSettled(requests)
      .then(responses => {
        const enumResponse = responses[0]
        let currencyOpts = null
        if (enumResponse.status == 'fulfilled') {
          const response = enumResponse.value
          if (response.jobCase != null && response[response.jobCase] != null) {
            const propertyList = response[response.jobCase]
            if (propertyList != null) {
              if (propertyList.CurrencyEnum != null) {
                const obj = propertyList.CurrencyEnum
                const codes = Object.keys(obj)
                const list = []
                for (const c of codes) {
                  const found = currencies.find(i => i.code == c)
                  const text = found != null && found.name != null? `${c} (${found.name})` : c
                  list.push({ value: c, text, num: obj[c] })
                }
                currencyOpts = list
              }
            }
          }
        }
        if (currencyOpts == null) {
          currencyOpts = []
        }
        let autoScheduling = true;
        const activeCurrency = currencyOpts.find(i => i.num > -1)
        let currencyCode = activeCurrency != null? activeCurrency.value : null;

        let idx = 0
        if (projectId != null) {
          idx+=1
          const projectResponse = responses[idx]
          if (projectResponse.status == 'fulfilled') {
            const response = projectResponse.value;
            const projData = response.data[response.data.jobCase] || [];
          
            if (projData.length > 0) {
              const project = projData[0];
              if (Object.hasOwn(project, 'autoScheduling')) {
                autoScheduling = project.autoScheduling;
              }

              if(project.currencyCode != null && currencyOpts.find(i => i.value == project.currencyCode && i.num > -1) != null) {
                currencyCode = project.currencyCode;
              }
            }
          }
        }
        return { autoScheduling, currencyCode };
      }).catch(() => {
        return { autoScheduling: true, currencyCode: null };
      })
    },
    ok() {
      this.errors.clear();
      this.alertMsg = null;
      this.alertMsgDetails.splice(0, this.alertMsgDetails.length);

      this.validateName();
      this.validateDescription();

      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          this.templateSubmit();
        } else {
          this.alertMsg = this.$t('error.attention_required');
          this.scrollToTop();
        }
      });
    },
    resetTemplateProperties() {
      const keys = Object.keys(this.template);
      this.errors.clear();
      this.$validator.reset();
      for (let i = 0, len = keys.length; i < len; i++) {
        if (keys[i] === 'uuId') {
          continue;
        }
        this.template[keys[i]] = null;
      }
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$t('error.authorize_action');
      } else if (response && 422 === response.status) {
        const feedback = response.data[response.data.jobCase][0];
        if (feedback.spot) {
          this.alertMsg = this.$t('error.attention_required');
          this.errors.add({
            field: `template.${feedback.spot}`,
            msg: this.$t(
              `error.${feedback.clue}`,
              feedback.args ? feedback.args : []
            )
          });
        } else {
          console.error(e); // eslint-disable-line no-console
          this.alertMsg = this.$t('error.internal_server');
        }
      } else {
        console.error(e); // eslint-disable-line no-console
        this.alertMsg = this.$t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#TEMPLATE_FORM_${this.id}`).scrollIntoView();
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    
    async templateSubmit() {
      const data = cloneDeep(this.template);

      data.durationAUM = 'D'; //Default and fixed value
      const { autoScheduling, currencyCode } = await this.getDefaultValues({ projectId: this.projectId });
      data.autoScheduling = autoScheduling;
      data.currencyCode = currencyCode;
      
      this.templatePost(data);
    },
    async templatePost(data) {
      this.state.isSubmitting = true;
      let result = await this.addTemplate(data);
      if (result.hasError) {
        this.alertMsg = result.msg;
        this.state.isSubmitting = false;
        return;
      }
      const templateId = result.templateId;
      const alertDetails = [];
      await TaskTemplateDataUtil.createTemplateTasksFromTasksAdv(this.taskIds.map(i => { return { uuId: i }}), { uuId: this.projectId }, { uuId: templateId });
 
      this.state.isSubmitting = false;
      if (alertDetails.length > 0) {
        this.alertMsgDetails.splice(
          0,
          this.alertMsgDetails.length,
          ...alertDetails
        );
        this.alertMsg = this.$t(`template.create_partial`);
        this.scrollToTop();
      } else {
        this.$emit('update:show', false);
        this.$emit('success', { msg: this.$t(`template.create`) });
      }
    },
    async addTemplate(data) {
      const result = {
        hasError: false,
        msg: this.$t(`template.create`)
      };
      let templateId = await templateProjectService.create([data])
      .then(response => {
        const data = response.data;
        return data[data.jobCase][0].uuId;
      })
      .catch(e => {
        result.hasError = true;
        result.msg = this.$t(`template.error.failed_to_create`);
        const response = e.response;
        if (response && 422 === response.status) {
          const feedback = response.data[response.data.jobCase][0];
          const clue = feedback.clue.trim().toLowerCase();
          if(['missing_argument','cannot_be_blank', 'invalid_value',
              'string_limit_exceeded', 'number_limit_exceeded'].includes(clue)) {
            result.msg = this.$t('error.attention_required');
            const fieldKey = `template.${feedback.args[0]}`;
            const args = [this.$t(`template.field.${feedback.args[0]}`)];
            let clueNotHandled = false;
            switch (clue) {
              case 'missing_argument': //Do nothing. Doesn't need additional argument
              case 'cannot_be_blank':
              case 'invalid_value':
                break;
              case 'string_limit_exceeded':
              case 'number_limit_exceeded':
                args.push(feedback.args[1]);
                break;
              default:
                clueNotHandled = true;
                result.msg = this.$('error.internal_server'); //reset the msg to internal_server error.
            }
            if (!clueNotHandled) {
              this.errors.add({
                field: fieldKey,
                msg: this.$t(`error.${clue}`, args)
              });
            }
          }
        }
      });
      result.templateId = templateId;
      return result;
    },
    validateName() {
      if(this.template.name == null ||this.template.name.trim().length == 0) {
        this.errors.add({
          field: `template.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('template.field.name')])
        });
        return false;
      } else {
        return true;
      }
    },
    validateDescription() {
      if(this.template.description && this.template.description.trim().length > 1000) {
        this.errors.add({
          field: `template.description`,
          msg: this.$i18n.t('error.column_limit_exceeded', [this.$i18n.t('template.field.description'), 1001])
        });
        return false;
      } else {
        return true;
      }
    },
  }
};
</script>
