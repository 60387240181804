var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        title: _vm.$t("staff.planner_settings.title"),
        "footer-class": "footerClass",
        "content-class": "shadow",
        "no-close-on-backdrop": "",
        scrollable: "",
      },
      on: {
        hidden: function ($event) {
          return _vm.$emit("update:show", false)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-button",
                {
                  attrs: {
                    disabled: !_vm.hasChanged,
                    size: "sm",
                    variant: "success",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.ok()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.ok")))]
              ),
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "danger" },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.cancel")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modalShow,
        callback: function ($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow",
      },
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.$t("staff.timescale") } },
        [
          _c(
            "b-form-checkbox",
            {
              staticClass: "setting-checkbox",
              attrs: { size: "lg" },
              model: {
                value: _vm.settingsData.weekNumbers,
                callback: function ($$v) {
                  _vm.$set(_vm.settingsData, "weekNumbers", $$v)
                },
                expression: "settingsData.weekNumbers",
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("staff.week_numbers")) + " ")]
          ),
          !_vm.hideWeekbase
            ? _c(
                "b-form-radio-group",
                {
                  attrs: { disabled: !_vm.settingsData.weekNumbers },
                  model: {
                    value: _vm.settingsData.weekBase,
                    callback: function ($$v) {
                      _vm.$set(_vm.settingsData, "weekBase", $$v)
                    },
                    expression: "settingsData.weekBase",
                  },
                },
                [
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "setting-checkbox",
                      attrs: {
                        size: "lg",
                        name: "weekBase",
                        value: "calendar_year",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("task.calendar_year")))]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "setting-checkbox",
                      attrs: {
                        size: "lg",
                        name: "weekBase",
                        value: "project_start",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("task.project_start")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-checkbox",
        {
          staticClass: "setting-checkbox",
          attrs: { size: "lg" },
          model: {
            value: _vm.settingsData.hideWeekends,
            callback: function ($$v) {
              _vm.$set(_vm.settingsData, "hideWeekends", $$v)
            },
            expression: "settingsData.hideWeekends",
          },
        },
        [_vm._v(" " + _vm._s(_vm.$t("staff.hide_weekends")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }