var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: "TEMPLATE_FORM_" + _vm.id },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _vm.state.isSubmitting
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            size: "sm",
                            variant: "success",
                            disabled: "",
                          },
                        },
                        [
                          _c("b-spinner", {
                            attrs: { small: "", type: "grow" },
                          }),
                          _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                        ],
                        1
                      )
                    : _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.disableOk,
                            size: "sm",
                            variant: "success",
                          },
                          on: { click: _vm.ok },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: "danger",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
              _c(
                "ul",
                { staticClass: "mb-0", attrs: { show: _vm.showErrorDetail } },
                [
                  _vm._l(_vm.alertMsgDetails, function (item, index) {
                    return [_c("li", { key: index }, [_vm._v(_vm._s(item))])]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("template.field.name"),
                "label-for": "name",
              },
            },
            [
              _c(
                "b-input-group",
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "name",
                      type: "text",
                      "data-vv-as": _vm.$t("template.field.name"),
                      "data-vv-name": "template.name",
                      "data-vv-delay": "500",
                    },
                    model: {
                      value: _vm.template.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.template, "name", $$v)
                      },
                      expression: "template.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showNameError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "   " + _vm._s(_vm.errors.first("template.name")) + " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                label: _vm.$t("template.field.description"),
                "label-for": "description",
              },
            },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "description",
                  placeholder: _vm.$t("template.placeholder.description"),
                  "data-vv-as": _vm.$t("template.field.description"),
                  "data-vv-name": "template.description",
                  "data-vv-delay": "500",
                  "max-rows": 6,
                  rows: 3,
                },
                model: {
                  value: _vm.template.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.template, "description", $$v)
                  },
                  expression: "template.description",
                },
              }),
              _c(
                "b-form-invalid-feedback",
                {
                  staticClass: "alert-danger form-field-alert",
                  class: { "d-block": _vm.showDescriptionError },
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "circle-exclamation"] },
                  }),
                  _vm._v(
                    "   " +
                      _vm._s(_vm.errors.first("template.description")) +
                      " "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }